<template>
  <footer class="bg-gray-900" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-20 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-4">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="font-semibold leading-6 text-white">Product</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.product" :key="item.name">
                  <a :href="item.href" class="leading-6 text-gray-300 hover:text-white">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="font-semibold leading-6 text-white">Company</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.company" :key="item.name">
                  <a :href="item.href" class="leading-6 text-gray-300 hover:text-white">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-1 md:gap-8">
            <div>
              <h3 class="font-semibold leading-6 text-white">Articles</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <nuxt-link
                    class="leading-6 text-gray-300 hover:text-white"
                    :to="{
                      path: `/compliant-software/`,
                    }"
                    >Ultimate Guide to HIPAA Compliant Software</nuxt-link
                  >
                </li>
                <li v-for="(article, index) in content.articles" :key="index">
                  <nuxt-link
                    class="leading-6 text-gray-300 hover:text-white"
                    :to="{
                      path: `/compliant-software/${article.uid}`,
                    }"
                    >{{ article.data.is_xyz_hipaa_compliant[0].text }}</nuxt-link
                  >
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="font-semibold leading-6 text-white">Legal</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.legal" :key="item.name">
                  <a :href="item.href" class="leading-6 text-gray-300 hover:text-white">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-16 border-t border-white/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
        <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
          &copy; {{ year }} TeachMeHIPAA LLC. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
const navigation = {
  product: [
    { name: "How it works", href: "#how-it-works" },
    { name: "Pricing", href: "#competitive-pricing" },
    { name: "Testimonials", href: "#testimonials" },
    { name: "FAQ", href: "#faq" },
    { name: "Features", href: "#features" },
    { name: "Who uses us", href: "#who-uses-us" },
    { name: "Blog", href: "https://teachmehipaa.com/blog" },
    { name: "Login", href: "https://app.teachmehipaa.com/" },
  ],
  company: [
    { name: "Reach us by email", href: "mailto:hello@teachmehipaa.com" },
    { name: "Reach us by phone", href: "tel:614-344-1098" },
    { name: "Schedule free consultation", href: "https://calendly.com/teachmehipaa/30min" },
  ],
  legal: [
    { name: "Privacy", href: "/privacy" },
    { name: "Terms", href: "/terms" },
  ],
};

const year = new Date().getFullYear();
const content = useContentStore();
</script>
